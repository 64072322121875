// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardScss from "./ProjectWizard.scss";

var css = ProjectWizardScss;

function ProjectWizard$Container(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([css.container])
            });
}

var Container = {
  make: ProjectWizard$Container
};

function ProjectWizard$Content(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    "pt-2",
                    css.content
                  ])
            });
}

var Content = {
  make: ProjectWizard$Content
};

function ProjectWizard$H1(props) {
  return JsxRuntime.jsx(H1.make, {
              className: css.h1,
              children: props.children
            });
}

var H1$1 = {
  make: ProjectWizard$H1
};

function ProjectWizard$H2(props) {
  return JsxRuntime.jsx(H2.make, {
              className: css.h2,
              children: props.children
            });
}

var H2$1 = {
  make: ProjectWizard$H2
};

function ProjectWizard$ValidationMessage(props) {
  var __className = props.className;
  var status = props.status;
  var className = __className !== undefined ? __className : "";
  if (status !== undefined && status.TAG !== "Ok") {
    return JsxRuntime.jsx(ErrorMessage.make, {
                className: Cx.cx([
                      css.error,
                      className
                    ]),
                children: status._0
              });
  } else {
    return null;
  }
}

var ValidationMessage = {
  make: ProjectWizard$ValidationMessage
};

export {
  css ,
  Container ,
  Content ,
  H1$1 as H1,
  H2$1 as H2,
  ValidationMessage ,
}
/* css Not a pure module */
